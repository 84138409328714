<script setup lang="ts">
import type { VTextField } from 'vuetify/lib/components/index.mjs';

interface Props {
  name: string;
  color: string;
  label: string;
  rules: ((v: string) => string | true)[];
  hidden: boolean;
  variant: VTextField['$props']['variant'];
}

const props = defineProps<Partial<Props>>();
const model = defineModel({ type: String });

const visible = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

defineExpose({ focus: () => inputRef.value?.focus() });
</script>

<template>
  <v-text-field
    ref="inputRef"
    v-bind="props"
    v-model="model"
    :class="{ hidden: props.hidden }"
    :type="visible ? 'text' : 'password'"
    :append-inner-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append-inner="visible = !visible" />
</template>

<style scoped lang="scss">
.hidden {
  position: absolute;
  transform: translate(-200%, -100%);
}
</style>
